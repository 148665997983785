import { useEffect } from 'react';

import { type TeamSizeChangedNotification } from '../../types/notification';
import { InfoIcon } from '../icons/InfoIcon';
import { XIcon } from '../icons/XIcon';
import { useNotificationDataSource } from '../Notification/Context';
import { type BaseNotificationProps } from '../Notification/type';

const TeamSizeChangedNotifcationView = (
  props: BaseNotificationProps & {
    notification: TeamSizeChangedNotification;
  }
): JSX.Element => {
  const maxMembers = props.notification.metadata.maxMembers;
  const notificationDataSource = useNotificationDataSource();

  useEffect(() => {
    const id = setTimeout(() => {
      notificationDataSource.dismiss(props.notification.id);
    }, 2000);

    return () => {
      clearTimeout(id);
    };
  }, [notificationDataSource, props.notification.id]);

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  return (
    <div className='flex flex-row items-center text-sm font-white p-3 h-14 bg-secondary rounded-xl w-100'>
      <div className='text-primary'>
        <InfoIcon className='w-5 h-5 fill-current' />
      </div>
      <div className='text-2xs text-white ml-2'>
        <div>
          Max team size is changed to <strong>{maxMembers}</strong>
        </div>
      </div>
      <div className='ml-auto' />
      <div className='cursor-pointer' onClick={handleClose}>
        <XIcon />
      </div>
    </div>
  );
};

// Default export easier to lazy import
// eslint-disable-next-line import/no-default-export
export default TeamSizeChangedNotifcationView;
